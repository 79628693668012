<template>
    <b-list-group-item>
        <!-- style="display: -webkit-box !important;" -->
        <div class="row">
            <div class="col-auto">
                <!-- Avatar -->
                <div class="avatar avatar-sm">
                    <div
                        class="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary"
                    >   
                    <router-link
                        :to="{
                            name: 'key',
                            params: {
                                locationId: locationKey.LocationId,
                                keyId: locationKey.Id,
                                companyId: locationKey.CompanyId,
                            },
                        }"
                    >
                        <!-- <i class="fi flaticon-key"></i> -->
                        <img :src="'/images/Key_MyZesec_64dp.png'" style="width: 32px; height: 32px;"/>
                    </router-link>
                    </div>
                </div>
            </div>
            <div class="col ml-n2">
            <!-- <div class="col-auto">  -->
                <!-- Heading -->
               <!--  {{ locationKey.Name }} -->
                <p class="small mb-0">
                    <router-link
                        :to="{
                            name: 'key',
                            params: {
                                locationId: locationKey.LocationId,
                                keyId: locationKey.Id,
                                companyId: locationKey.CompanyId,
                            },
                        }"
                    >{{ keyName(locationKey.Name) }}</router-link>
                </p>
                <small class="text-muted mr-2" >
                    {{
                    $t("common.count_doors", {
                    count: locationKey.DevicesCount || 0,
                    })
                    }}
                </small>
                <small class="text-muted">
                    {{
                    $t("common.count_users", {
                    count: locationKey.UsersCount || 0,
                    })
                    }}
                </small>
            </div>
        </div>
    </b-list-group-item>
</template>
<script>
export default {
    name: "location-key-list-item",
    props: ["locationKey"],
    methods: {
        keyName(name){
            if(name.includes("Key of: ")){
                name = name.replace("Key of: ","");
            }
            return name;
        },
    },
};
</script>
