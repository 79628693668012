var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-list-group-item',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"avatar avatar-sm"},[_c('div',{staticClass:"avatar-title font-size-lg bg-primary-soft rounded-circle text-primary"},[_c('router-link',{attrs:{"to":{
                        name: 'key',
                        params: {
                            locationId: _vm.locationKey.LocationId,
                            keyId: _vm.locationKey.Id,
                            companyId: _vm.locationKey.CompanyId,
                        },
                    }}},[_c('img',{staticStyle:{"width":"32px","height":"32px"},attrs:{"src":'/images/Key_MyZesec_64dp.png'}})])],1)])]),_c('div',{staticClass:"col ml-n2"},[_c('p',{staticClass:"small mb-0"},[_c('router-link',{attrs:{"to":{
                        name: 'key',
                        params: {
                            locationId: _vm.locationKey.LocationId,
                            keyId: _vm.locationKey.Id,
                            companyId: _vm.locationKey.CompanyId,
                        },
                    }}},[_vm._v(_vm._s(_vm.keyName(_vm.locationKey.Name)))])],1),_c('small',{staticClass:"text-muted mr-2"},[_vm._v(" "+_vm._s(_vm.$t("common.count_doors", { count: _vm.locationKey.DevicesCount || 0, }))+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t("common.count_users", { count: _vm.locationKey.UsersCount || 0, }))+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }