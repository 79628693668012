var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"header-body"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[_c('h6',{staticClass:"header-pretitle"},[_vm._v(" "+_vm._s(_vm.$t("locations.location"))+" ")]),_c('h1',{staticClass:"header-title"},[_vm._v(" "+_vm._s(_vm.$t("keys.keys"))+" ")])])])])])]),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"}),_c('form',{staticClass:"mr-3"},[_c('Select2',{attrs:{"options":_vm.perPageOptions,"settings":{
                  minimumResultsForSearch: -1,
                  containerCssClass:
                  'custom-select custom-select-sm form-control-flush',
                  dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                }},on:{"change":_vm.paginatedList},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),_c('form',{staticClass:"mr-3"},[_c('Select2',{attrs:{"options":_vm.sortOrderOptions,"settings":{
                  minimumResultsForSearch: -1,
                  containerCssClass:
                    'custom-select custom-select-sm form-control-flush',
                  dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                }},model:{value:(_vm.sortOrder),callback:function ($$v) {_vm.sortOrder=$$v},expression:"sortOrder"}})],1),(
                _vm.getActiveLocation && _vm.btnFlag 
              )?_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.createKey}},[_vm._v(_vm._s(_vm.$t("keys.create")))]):_vm._e()],1),_c('div',{staticClass:"card-header"},[_c('form',[_c('div',{staticClass:"input-group input-group-flush input-group-merge"},[_c('b-form-input',{staticClass:"form-control form-control-prepended search",attrs:{"placeholder":_vm.$t('common.search'),"type":"search"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('div',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text"},[_c('span',{staticClass:"fe fe-search"})])])],1)])]),_c('div',{staticClass:"card-body"},[_c('b-pagination',{staticClass:"pb-2",attrs:{"total-rows":_vm.keysList.length,"per-page":_vm.perPage,"aria-controls":"my-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('zs-list-transition',{attrs:{"showLoader":_vm.showLoader},scopedSlots:_vm._u([{key:"content",fn:function(){return _vm._l((_vm.paginatedList),function(key){return _c('location-key-list-item',{key:key.Id,attrs:{"locationKey":key}})})},proxy:true},{key:"empty",fn:function(){return [_c('empty-list',{attrs:{"title":_vm.$t('keys.no_keys')}})]},proxy:true}])}),_c('b-pagination',{staticClass:"pb-2",attrs:{"total-rows":_vm.keysList.length,"per-page":_vm.perPage,"aria-controls":"my-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)]),(_vm.getActiveLocation)?_c('div',{attrs:{"id":"modals"}},[_c('zs-key-modal',{attrs:{"locationId":_vm.getActiveLocation.Id}})],1):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }